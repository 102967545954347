<template>
  <div>
    <loader />
    <router-view />
  </div>
</template>
<script>
import Loader from "@/components/Loader";

export default {
  components: {
    Loader,
  },  
  mounted(){
    this.$store.state.$app = this;
  }
};
</script>

