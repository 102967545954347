export default [
    {
        key: "id",
        label: "#",
        thStyle: {
            width: "5%",
            textAlign: "center",
        },
        thClass: "text-muted small",
        tdClass: "p-0 text-muted text-center pt-1",
    },
    {
        key: "description",
        label: "Grupo",
        thStyle: {
            width: "40%",
        },
        thClass: "text-muted small",
    },    
    {
        key: "active",
        label: "Status",
        thStyle: {
            width: "5%",
        },
        thClass: "text-muted small",
    },
    
]