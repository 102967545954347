import { _ } from 'core-js';
import Http from 'Http';
import Moment from 'moment'
import Axios from 'axios'

const state = {
    plans: []
}
const getters = {

}
const actions = {
    async list_init({ dispatch, commit }) {

        const [data] = await Promise.all([

            dispatch('get_data')

        ]).catch(e => { console.log(e) })

        console.log(data)

        commit('SET_PLANS', plans)

    },
    async get_data({ }) {

        const delay = t => new Promise(resolve => setTimeout(resolve, t));

        try {

            const response = delay(3000).then(() => {return [{nome:'aaaaa', idade:'bbbb'}]});

            return response

        } catch (e) {

            this.state.$app.makeToast({
                title: "Opz! Algo deu errado",
                msg: 'Erro ao listar planos',
                variant: 'danger'
            });

        }
    }
}
const mutations = {

    ["SET_PLANS"]: (state, plans) => {

        state.plans = plans

    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}