export default [
    {
        key: "id",
        label: "#",
        thStyle: {
            width: "5%",
            textAlign: "center",
        },
        thClass: "text-muted small",
        tdClass: "p-0 text-muted text-center pt-1",
    },
    {
        key: "name",
        label: "Nome",
        thStyle: {
            width: "20%",
        },
        thClass: "text-muted small",
    },    
    {
        key: "email",
        label: "Email",
        thStyle: {
            width: "30%",
        },
        thClass: "text-muted small",
    },    
    {
        key: "description",
        label: "Grupo",
        thStyle: {
            width: "30%",
        },
        thClass: "text-muted small",
    },    
    {
        key: "active",
        label: "Ativo",
        thStyle: {
            width: "5%",
        },
        thClass: "text-muted small",
    },
]