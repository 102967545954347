import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import modules from './modules';

// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage,
//   key: 'local-db',
//   reducer: state => ({
//     auth: state.auth
//   })
// });

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
//   plugins: [vuexLocal.plugin]
});