export default
    [
        //List
        {
            path: '/grupos',
            name: 'groups',
            meta: {
                icon: 'file-code',
                title: 'Lista de Grupos'
            },
            component: () => import('@/views/groups/list/Index')
        },
        {
            path: '/grupos/adicionar',
            name: 'groups.add',
            meta: {
                icon: 'file-code',
                title: 'Criar Grupo'
            },
            component: () => import('@/views/groups/list/AddView')
        },
        {
            path: '/grupos/:groupId/editar',
            name: 'groups.groupId.edit',
            meta: {
                icon: 'file-code',
                title: 'Editar Grupo'
            },
            component: () => import('@/views/groups/list/EditView')
        },
        
        
    
    ]