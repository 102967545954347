<template>
  <div>
    <b-row
      class="mt-4"
      v-for="(field, index_row) in schemas"
      :key="`${index_row}${hexKeyRow}`"
    >
      <b-col
        v-for="(schema, index_col) in field"
        :md="schema.col_size"
        :key="`${index_col}${hexKeyCol}`"
      >
        <label>{{ schema.label }}</label>
        <template
          v-if="
            schema.type == undefined ||
            schema.type == 'number' ||
            schema.type == 'date'
          "
        >
          <b-input
            :class="[errors.includes(schema.model) ? 'border border-danger' : false]"
            :type="schema.type"
            v-model="model[schema.model]"
            :disabled="schema.disabled || false"
            @input="$emit('input', $event)"
          />
        </template>
        <template v-if="schema.type == 'select'">
          <b-form-select
            :options="schema.selectOptions"
            :class="[errors.includes(schema.model) ? 'border border-danger' : false]"
            :disabled="schema.disabled || false"
            v-model="model[schema.model]"
            @change="$emit('input', $event)"
          >
          </b-form-select>
        </template>
        <span v-if="errors.includes(schema.model)" :class="[errors.includes(schema.model) ? 'text-danger' : false]">{{
          schema.errorMsg || "Campo obrigatório"
        }}</span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import crypto from "crypto";
export default {
  props: ["schemas", "model"],
  data() {
    return {
      hexKeyRow: "",
      hexKeyCol: "",
      errors: [],
    };
  },  
  methods: {    
    check_required() {
      this.errors = [];
      const flat = _.flatten(this.schemas);
      _.forEach(flat, (item) => {
        if (item.required) {
          
          if (!this.model[item.model]) {
            
            this.errors.push(item.model);
          }
        }
      });
      return this.errors;
    },
  },
  mounted() {
    this.hexKeyRow = crypto.randomBytes(8).toString("hex");
    this.hexKeyCol = crypto.randomBytes(8).toString("hex");
  },
};
</script>

<style>
</style>