import Http from 'Http';

const initState = {
    users: [],
    unities: [],
    groupOptions:[]
}
const state = {
    users: [],
    unities: [],
    groupOptions:[]
}
const getters = {

}
const actions = {
    async users_init({ dispatch, commit }) {        

        const [users, unities] = await Promise.all([

            dispatch('get_users'),
            dispatch('get_unities'),

        ]).catch(e => {            

            this.state.$app.toastError('users/users_init', e)
           
        })        

        commit('SET', { state: 'users', data: users })
        commit('SET', { state: 'unities', data: unities })        

    },
    async get_users({ }) {

        let data = []

        try {

            const response = await Http().get('/users')            

            data = response.data

        } catch ({ response }) {

            throw response

            // this.state.$app.toastError('unities/save_bank_account', response)

        }

        return data
    },
    async get_unities({ }) {

        let data = []

        try {

            const response = await Http().get('/user-unity')            

            data = response.data

        } catch ({ response }) {

            throw response

            // this.state.$app.toastError('unities/save_bank_account', response)

        }

        return data
    },
    async get_user_by_id({ }, id) {        

        let data = ''

        try {

            const response = await Http().get(`/user/${id}`)            

            data = response.data

        } catch ({ response }) {

            throw response

            // this.state.$app.toastError('unities/save_bank_account', response)

        }

        return data
    },
    async save_user({ commit }, user) {

        try {

            const response = await Http().post('/user', user)

            if (response.status == 200 || response.status == 201) {

                commit('PUSH', { state: 'users', data: user })

                this.state.$app.toastSuccess('Usuário criado com sucesso!')

            }

            return true

        } catch ({ response }) {

            console.log(response)

            if (!response.status == 400) {

                this.state.$app.toastError('users/save_user', response)

            } else {
                return false
            }

        }


    },
    async update_user({ commit }, user) {

        try {

            const response = await Http().put('/user', user)

            if (response.status == 200 || response.status == 201) {

                commit('PUSH', { state: 'users', data: user })

                this.state.$app.toastSuccess('Usuário alterado com sucesso!')

            }

            return true

        } catch ({ response }) {

            console.log(response)

            if (!response.status == 400) {

                this.state.$app.toastError('users/save_user', response)

            } else {
                return false
            }

        }


    },
    async get_groups_by_unity_id({ commit }, unityId) {        

        try {

            const response = await Http().get(`/group/unity/${unityId}` )            

            if (response.status == 200 || response.status == 201) {

                return response.data        

            }            

        } catch ({ response }) {

            if (!response.status == 400) {

                this.state.$app.toastError('users/save_user', response)

            } else {
                return []
            }

        }
    }
}
const mutations = {

    ["SET"]: (state, data) => {

        state[data.state] = data.data

    },
    ["PUSH"]: (state, data) => {

        state[data.state].push(data.data)

    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}