import Cookie from "js-cookie"
import apiCheckpoint from 'HttpCheckpoint'
import Api from 'Http'

const state = {
    currentUser: {},
    userMenu: [],
    menuItems: []
}
const getters = {}
const actions = {
    async init({ commit }) {

        const refreshToken = Cookie.get('refresh_token')

        const applicationId = process.env.VUE_APP_APPLICATION_ID

        const response = await apiCheckpoint.post('/refresh/token', { refresh: refreshToken, application_id: applicationId })

        commit('SET', { state: 'currentUser', data: response.data.user })

        commit('SET', { state: 'userMenu', data: response.data.menu })

    },
    async login({ dispatch }, data) {

        let isLogged = false

        try {

            const auth = await apiCheckpoint.post('authenticate',
                {
                    email: data.username,
                    password: data.password,
                }
            )

            Cookie.set('access_token', auth.data.token)

            Cookie.set('refresh_token', auth.data.refreshToken)

            await dispatch('init')

            isLogged = true

        } catch (e) {

            console.log(e)

        }

        return isLogged
    },
    async logout({ commit }) {

        try {

            Cookie.remove('access_token')

            Cookie.remove('refresh_token')

            commit('SET', { state: 'currentUser', data: {} })

        } catch ({ response }) {

            throw response

        }
    },
    async cross_domain({ dispatch, commit }, refreshData) {       

        let data = ''

        try {

            const response = await apiCheckpoint.post('/refresh/token', refreshData)            

            if (response.status == 200 || response.status == 201) {

                data = response.data

            }
        } catch ({ response }) {
            console.log(response)
        }

        if (data) {

            Cookie.set('access_token', data.token)

            Cookie.set('refresh_token', data.refreshToken)

            await dispatch('init')

            return true
        }
        return false
    }
}
const mutations = {
    ['SET_LOGGED']: (state, logged) => {
        state.authenticated = logged
    },
    ['SET_CURRENT_USER']: (state, user) => {
        state.currentUser = user
    },
    ['SET']: (state, data) => {
        state[data.state] = data.data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}