<template>
  <div>
    <b-button class="mt-4 ml-4" @click="closeFacility()">Fechar</b-button>
    <Card :title="'Filtros'" class="m-4 p-2">
      <b-row>
        <b-col>
          <label>Aplicação</label>
          <b-form-select
            :options="selectOptionsApplications"
            v-model="selectedApplication"
          >
            <template #first>
              <b-form-select-option :value="''"> Todos </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col>
          <label>Menu</label>
          <b-form-select :options="selectOptionsMenus" v-model="selectedMenu">
            <template #first>
              <b-form-select-option :value="''"> Todos </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </Card>
    <b-button variant="primary" class="ml-4" @click="newMenu()"
      >Novo Menu Raiz (sem submenu)</b-button
    >
    <b-button variant="primary" class="ml-4" @click="newSubMenu()"
      >Novo Submenu</b-button
    >
    <b-button variant="primary" class="ml-4" @click="newComponent()"
      >Novo Componente</b-button
    >
    <Card title="Lista" class="m-4">
      <b-table
        sticky-header="60vh"
        :items="filteredPermissions"
        :fields="fields"
      >
        <template #cell(application_id)="data">
          {{ applicationsById[data.item.application_id].name }}
        </template>
        <template #cell(type)="data">
          {{ check_type(data.item) }}
        </template>
        <template #cell(description)="data">
          {{
            data.item.type == "1"
              ? data.item.description.split(".")[
                  data.item.description.split(".").length - 1
                ]
              : ""
          }}
        </template>
        <template #cell(id)="data">
          <b-dropdown
            class="p-0 m-0 d-flex"
            size="md"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <fa-icon size="1x" icon="bars" />
            </template>

            <b-dropdown-item
              title="Detalhes"
              @click="$router.push(`/grupos/${data.item.id}/editar`)"
              >Detalhes</b-dropdown-item
            >
            <b-dropdown-item
              title="Detalhes"
              @click="
                editPermission({
                  type: check_type(data.item),
                  id: data.item.id,
                })
              "
              >Editar</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>
    </Card>
  </div>
</template>

<script>
import FormMenuRoot from "./components/FormMenuRoot.vue";
import FormSubMenu from "./components/FormSubMenu.vue";
import FormComponent from "./components/FormComponent.vue";
export default {
  data() {
    return {
      selectedApplication: "",
      selectedMenu: "",
      fields: [
        {
          key: "id",
          label: "#",
          thStyle: {
            width: "5%",
            textAlign: "center",
          },
          thClass: "text-muted small",
          tdClass: "p-0 text-muted text-center pt-1",
        },
        {
          key: "application_id",
          label: "Aplicação",
          thStyle: {
            width: "5%",
          },
          thClass: "text-muted small",
        },
        {
          key: "menu",
          label: "Menu",
          thStyle: {
            width: "10%",
          },
          thClass: "text-muted small",
        },
        {
          key: "sub_menu",
          label: "SubMenu",
          thStyle: {
            width: "5%",
          },
          thClass: "text-muted small",
        },
        {
          key: "type",
          label: "Tipo",
          thStyle: {
            width: "5%",
          },
          thClass: "text-muted small",
        },
        {
          key: "description",
          label: "Componente",
          thStyle: {
            width: "5%",
          },
          thClass: "text-muted small",
        },
        {
          key: "route",
          label: "Rota",
          thStyle: {
            width: "5%",
          },
          thClass: "text-muted small",
        },
      ],
    };
  },
  computed: {
    permissions() {
      return this.$store.state.permissionsFacility.permissions;
    },
    applications() {
      return this.$store.state.permissionsFacility.applications;
    },
    applicationsById() {
      return _.keyBy(this.applications, "id");
    },
    selectOptionsMenus() {
      const menus = _.groupBy(this.permissions, "menu");
      return _.map(Object.keys(menus), (menu) => {
        return { value: menu, text: menu };
      });
    },
    selectOptionsApplications() {
      return _.map(this.applications, (application) => {
        return { value: application.id, text: application.name };
      });
    },
    filteredPermissions() {
      const filteredApplication = _.filter(this.permissions, (permission) => {
        return this.selectedApplication
          ? permission.application_id == this.selectedApplication
          : permission;
      });
      const filteredMenu = _.filter(filteredApplication, (application) => {
        return this.selectedMenu
          ? application.menu == this.selectedMenu
          : application;
      });
      return filteredMenu;
    },
  },
  methods: {
    check_type(item) {
      if (item.type == "0") {
        if (item.menu == item.sub_menu) {
          return "(Menu Raiz)";
        } else {
          return "(Sub Menu)";
        }
      } else {
        return "(Componente)";
      }
    },
    closeFacility() {
      window.location.href = "/";
    },
    newMenu() {
      if (this.selectedApplication) {
        this.$router.addRoute({
          path: `/facility/novo_menu/:applicationId`,
          component: FormMenuRoot,
        });
        this.$router.push(`/facility/novo_menu/${this.selectedApplication}`);
      }
    },
    newSubMenu() {
      if (this.selectedApplication) {
        this.$router.addRoute({
          path: `/facility/novo_submenu/:applicationId`,
          component: FormSubMenu,
        });
        this.$router.push(`/facility/novo_submenu/${this.selectedApplication}`);
      }
    },
    newComponent() {
      if (this.selectedApplication) {
        this.$router.addRoute({
          path: `/facility/novo_componente/:applicationId`,
          component: FormComponent,
        });
        this.$router.push(`/facility/novo_componente/${this.selectedApplication}`);
      }
    },
    editPermission(data) {
      if (data.type == "(Menu Raiz)") {
        this.$router.addRoute({
          path: "/facility/:id/menu",
          component: FormMenuRoot,
        });
        this.$router.push(`/facility/${data.id}/menu`);
      } else if (data.type == "(Sub Menu)") {
        this.$router.addRoute({
          path: `/facility/editar_submenu/:permissionId`,
          component: FormSubMenu,
        });
        this.$router.push(`/facility/editar_submenu/${data.id}`);
      } else {
        
        this.$router.addRoute({
          path: `/facility/editar_componente/:permissionId`,
          component: FormComponent,
        });
        this.$router.push(`/facility/editar_componente/${data.id}`);
      
      }
    },
  },
  mounted() {
    this.$store.dispatch("permissionsFacility/init");
  },
};
</script>

<style>
</style>