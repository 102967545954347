export default
    [
        //List
        {
            path: '/usuarios',
            name: 'users',
            meta: {
                icon: 'file-code',
                title: 'Lista de Usuários'
            },
            component: () => import('@/views/users/list/Index')
        },
        {
            path: '/usuarios/adicionar',
            name: 'users.add',
            meta: {
                icon: 'file-code',
                title: 'Adicionar Usuário'
            },
            component: () => import('@/views/users/list/Add')
        },
        {
            path: '/usuarios/:userId/editar',
            name: 'users.edit',
            meta: {
                icon: 'file-code',
                title: 'Editar Usuário'
            },
            component: () => import('@/views/users/list/Edit')
        },
        
    
    ]