import { _ } from 'core-js';
import Http from 'Http';

const initState = {
    groups: [],
    unities:[]
}
const state = {
    groups: [],
    unities:[]
}
const getters = {

}
const actions = {
    async groups_init({ dispatch, commit }) {

        const [groups] = await Promise.all([

            dispatch('get_groups')

        ]).catch(e => {

            this.state.$app.toastError('groups/groups_init', e)

        })

        commit('SET', { state: 'groups', data: groups })

    },
    async groups_add_edit_init({ dispatch, commit }) {

        const [unities] = await Promise.all([

            dispatch('get_user_unities')

        ]).catch(e => {

            this.state.$app.toastError('groups/groups_init', e)

        })

        commit('SET', { state: 'unities', data: unities })

    },
    async get_groups() {

        let data = []

        try {

            const response = await Http().get('/group/list')

            console.log('group list', response)

            data = response.data

        } catch ({ response }) {

            throw response

        }

        return data
    },
    async get_user_unities() {

        let data = []

        try {

            const response = await Http().get('/user-unity')

            data = response.data

        } catch ({ response }) {

            throw response

        }

        return data
    },

    async get_group_by_id({}, groupId) {

        let data = []

        try {

            const response = await Http().get(`/group/${groupId}`)

            return response.data

            data = response.data

        } catch ({ response }) {

            throw response

        }

        return data
    },
    async save_group({}, data) {
        
        try {

            const response = await Http().post(`/group`, data)

            if(response.status == 200 || response.status == 201){

                this.state.$app.toastSuccess('Grupo criado com sucesso!')

                return true

            }            

        } catch ({ response }) {

            throw response

        }

        return false
    },
    async update_group({}, data) {
        
        try {

            const response = await Http().put(`/group`, data)

            if(response.status == 200 || response.status == 201){

                this.state.$app.toastSuccess('Grupo criado com sucesso!')

                return true

            }            

        } catch ({ response }) {

            throw response

        }

        return false
    }
}
const mutations = {
    ["SET"]: (state, data) => {

        state[data.state] = data.data

    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}