export default (unities) => {
  console.log(unities)
  return [
    [
      {
        label: "Nome",
        col_size: "4",
        model: "name",
        required: true,
      },
      {
        label: "Emails",
        col_size: "4",
        model: "email",
        required: true,
      },
      {
        label: "Unidades",
        model: "type",
        required: true,
        type: 'select',
        selectOptions: unities,
      },
      {
        label: "Grupos",
        model: "type",
        required: true,
        type: 'select',
        selectOptions: [
          {
            value: "user",
            text: "Usuário",
          },
          {
            value: "admin",
            text: "Administrador",
          },
        ],
      }
    ],
  ]
}


