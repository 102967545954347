<template>
  <div>
    <div class="wrapper">
      <nav id="sidebar">
        <sidebar />
      </nav>
      <section id="content" class="d-flex flex-column">
        <navbar />
        <router-view class="pr-4" />
        <footer
          id="footer"
          class="text-muted text-right px-4 py-2 pb-2 flex-grow-0"
        >
          <small>Pague App - </small>
        </footer>
      </section>
    </div>
    <SweetModal ref="facilityPassword">
      <label>Digite a senha:</label>
      <b-input type="password"></b-input>
      <template #button>
        <b-button>Cancelar</b-button>
        <b-button @click="checkPassword()">Confirmar</b-button>
      </template>
    </SweetModal>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from "@/components/Sidebar.vue";
import Navbar from "@/components/Navbar.vue";
import Facility from "@/views/permissionsFacility/Index";
export default {
  data() {
    return {
      password: "123456",
    };
  },
  components: {
    Sidebar,
    Navbar,
  },
  methods: {
    checkPassword() {
      //if (this.password == "123456") {
        this.$router.addRoute({ path: "/facility", component: Facility });
        this.$router.push("/facility");
      //}
    },
  },
  mounted() {
    // this.checkPassword()
    this.$store.state.$app = this;
    let keysPressed = {};
    document.addEventListener("keydown", (event) => {
      if (keysPressed[event.key]) {
        return false;
      } else {
        keysPressed[event.key] = true;
      }
      if (keysPressed["Control"] && keysPressed["Shift"] && keysPressed["F1"]) {
        this.$refs.facilityPassword.open();
      }
    });

    document.addEventListener("keyup", (event) => {
      delete keysPressed[event.key];
    });
  },
};
</script>


<style lang="scss">
.wrapper {
  overflow-x: hidden;
  display: flex;
  width: 100%;
  align-items: stretch;
  background-color: #efeeea
}
#sidebar {
  min-width: 250px;
  max-width: 250px;
  background-color: #efeeea;
  transition: all 0.3s;
  z-index: 0;
}
#content {
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s;
  z-index: 0;
}
@media (max-width: 1024px) {
  #sidebar {
    margin-left: -200px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
}
// #app-grid {
// 	overflow-x: hidden;
// 	display: -moz-grid;
// 	display: -ms-grid;
// 	display: grid;
// 	min-height: 100vh;
// 	grid-template:
// 		'navigation content' 1fr
// 		'navigation footer' auto
// 		// "navigation header" $header-height * 2
// 		/ auto 0.95fr;

// 	// #header { grid-area: header; }
// 	#navigation {
// 		grid-area: navigation;
// 	}
// 	#footer {
// 		grid-area: footer;
// 	}
// 	#content {
// 		grid-area: content;
// 	}
// 	#header-bg {
// 		border-bottom-left-radius: $header-bg-radius;
// 	}
// }
</style>