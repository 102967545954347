// import camelCase from 'lodash/camelCase';

import auth from '@/store/modules/auth.js'

const modules = {};

modules.auth = auth

const requireModules = require.context('@/views', true, /\.js$/);

requireModules.keys().forEach(fileName => {

  const folders = fileName.split('/')  

  if(folders[folders.length - 1] == 'index.js' && folders[folders.length - 2] == 'store'){    
    
    modules[folders[1]] = requireModules(fileName).default

  }
 
  
  // if (fileName === './index.js') return;

  // const moduleName = camelCase(fileName.replace(/(\.\/|\.js)/g, ''));

  // modules[moduleName] = requireModules(fileName).default;
});

export default modules;