import { _ } from 'core-js';
import HttpFacility from 'HttpFacility';

const initState = {

}
const state = {
    permissions:[],
    applications:[],
}
const getters = {

}

const actions = {
    async init({ commit, dispatch }) {
        const [permissions, applications] = await Promise.all([

            dispatch('get_permissions'),
            dispatch('get_applications'),

        ]).catch(e => {

            this.state.$app.toastError('permissionsFacility/init', e)

        })        

        commit("SET", { state: 'permissions', data: permissions })
        commit("SET", { state: 'applications', data: applications })

    },
    // async permissions_init({ dispatch, commit }) {

    //     const [groupPermissions] = await Promise.all([

    //         dispatch('get_group_permissions'),

    //     ]).catch(e => {

    //         this.state.$app.toastError('unities/save_bank_account', e)

    //     })

    //     commit("SET", { state: 'groupPermissions', data: groupPermissions })

    // },
    // async permissions_add_edit_init({ dispatch, commit }) {

    //     const [permissions, unities, groups] = await Promise.all([

    //         dispatch('get_permissions'),

    //         dispatch('get_user_unities'),

    //         dispatch('get_groups'),

    //     ]).catch(e => {

    //         this.state.$app.toastError('unities/save_bank_account', e)

    //     })

    //     let sub = []

    //     _.forEach(permissions, (permission, unityIndex) => {

    //         const unity = {

    //             description: permission.name,

    //             children: [],

    //             selectorKeys: []
    //         }

    //         sub.push(unity)

    //         let rootSelector = sub[unityIndex].selectorKeys

    //         _.forEach(permission.permission, (item) => {

    //             let temp = sub[unityIndex]

    //             const keys = item.description.split('.')

    //             _.forEach(keys, (key, idx) => {

    //                 if (idx == keys.length - 1) {

    //                     temp.children.push({ description: key, id: item.id })

    //                     temp.selectorKeys.push(item.id)

    //                     rootSelector.push(item.id)

    //                 } else {

    //                     const index = _.findIndex(temp.children, (findItem) => {

    //                         return findItem.description == key

    //                     })

    //                     if (index < 0) {

    //                         temp.children.push({ description: key, children: [], selectorKeys: [] })

    //                         const subIndex = _.findIndex(temp.children, (findItem) => {

    //                             return findItem.description == key

    //                         })

    //                         temp = temp.children[subIndex]

    //                     } else {

    //                         temp = temp.children[index]

    //                     }
    //                 }
    //             })
    //         })
    //     })

    //     commit("SET", { state: 'permissions', data: sub })

    //     commit("SET", { state: 'unities', data: unities })

    //     commit("SET", { state: 'groups', data: groups })

    // },

    async create_permission({ }, data) {        

        try {

            const response = await HttpFacility().post('/permissions', data)    
            
            this.state.$app.toastSuccess('Permissão criadas com sucesso!')

            data = response.data.payload

        } catch ({ response }) {

            throw response            

        }

        return data
    },
    async update_permission({ }, data) {        

        try {

            const response = await HttpFacility().put('/permissions', data)    
            
            this.state.$app.toastSuccess('Permissão alterada com sucesso!')

            data = response.data.payload

        } catch ({ response }) {

            throw response            

        }

        return data
    },
    async get_permissions({ }) {

        let data = []

        try {

            const response = await HttpFacility().get('/permissions')            

            data = response.data.payload

        } catch ({ response }) {

            throw response            

        }

        return data
    },
    async get_permission_by_id({ }, permissionId) {        

        let data = []

        try {

            const response = await HttpFacility().get(`/permissions/${permissionId}`)            

            data = response.data.payload

        } catch ({ response }) {

            throw response            

        }

        return data
    },
    async get_applications({ }) {

        let data = []

        try {

            const response = await HttpFacility().get('/applications')
            
            data = response.data.payload

        } catch ({ response }) {

            throw response            

        }

        return data
    },
    // async get_group_permissions({ }) {

    //     let data = []

    //     try {

    //         const response = await Http().get('/group-permission')

    //         console.log(response)

    //         data = response.data

    //     } catch ({ response }) {

    //         throw response

    //     }

    //     return data
    // },
    // async get_group_permissions_by_id({ commit }, groupId) {        

    //     let data = []

    //     try {

    //         const response = await Http().get(`/group-permission/edit/${groupId}`)   

    //         commit ('SET', {state:"permissionsEditing", data:response.data})

    //         data = response.data

    //     } catch ({ response }) {

    //         throw response

    //     }

    //     return data
    // },
    // async get_user_unities({ }) {

    //     let data = []

    //     try {

    //         const response = await Http().get('/user-unity')

    //         console.log(response)

    //         data = response.data

    //     } catch ({ response }) {

    //         throw response

    //     }

    //     return data
    // },
    // async get_groups({ }) {

    //     let data = []

    //     try {

    //         const response = await Http().get('/group')   

    //         console.log(response)

    //         data = response.data

    //     } catch ({ response }) {

    //         throw response

    //     }

    //     return data
    // },
    // async save_permissions({ }, permissions) {

    //     try {

    //         const response = await Http().post('/group-permission', permissions)         

    //         if(response.status == 200 || response.status == 201){

    //             this.state.$app.toastSuccess('Permissões criadas com sucesso!')

    //             return true

    //         }


    //     } catch ({ response }) {

    //         this.state.$app.toastError('permissions/save_permissions', response)

    //     }

    //     return false
    // },
    // async update_permissions({ }, permissions) {

    //     try {

    //         const response = await Http().patch('/group-permission', permissions)         

    //         if(response.status == 200 || response.status == 201){

    //             this.state.$app.toastSuccess('Permissões criadas com sucesso!')

    //             return true

    //         }


    //     } catch ({ response }) {

    //         this.state.$app.toastError('permissions/save_permissions', response)

    //     }

    //     return false
    // },

}
const mutations = {

    ["SET"]: (state, data) => {

        state[data.state] = data.data

    },
    ["PUSH"]: (state, data) => {

        state[data.state].push(data.data)

    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}