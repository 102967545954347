export default
    [
        //List
        {
            path: '/example',
            name: 'example',
            meta: {
                icon: 'file-code',
                title: 'Exemplo'
            },
            component: () => import('@/views/exampleView/Index')
        },
        
    
    ]