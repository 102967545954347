<template>
  <div>
    <b-button class="mt-4 ml-4" @click="$router.push('/facility')"
      >Voltar</b-button
    >
    <Card :title="'Novo Component'" class="m-4 pb-4">
      <b-row>
        <b-col>
          <multiselect
            :multiple="true"
            :taggable="false"
            :searchable="false"
            :closeOnSelect="false"
            return="value"
            v-model="tempSelected"
            :options="tempMenu || []"
            @remove="tag_remove"
            @input="change_temp_menu"
          >
            <template #noOptions>Sem items</template>
          </multiselect>
        </b-col>
      </b-row>
      <b-row class="pt-2">
        <!-- <b-col>
          <label>Menu</label>
          <b-form-select
            :options="selectOptionsMenus"
            @change="selectedMenu"
            v-model="tempSelected"
          >
            <template #first>
              <b-form-select-option :value="'new'"> Novo </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col>
          <label>sub_menu</label>
          <b-input type="text" v-model="model.sub_menu" id="sub_menu-info" />
        </b-col> -->
        <b-col md="2">
          <label>icon</label>
          <b-input v-model="model.icon" id="icon-info" />
        </b-col>
        <b-col>
          <label>component</label>
          <b-input v-model="componentName" id="title-info" />
        </b-col>
        <b-col>
          <label>title</label>
          <b-input v-model="model.title" id="title-info" />
        </b-col>
        <!-- <b-col>
          <label>description</label>
          <b-input v-model="model.description" id="description-info" />
        </b-col> -->
      </b-row>
      <b-row>        
        <b-col>
          <label>path</label>
          <b-input v-model="model.path" id="path-info" />
        </b-col>
        <b-col>
          <label>name</label>
          <b-input v-model="model.name" id="name-info" />
        </b-col>
        <b-col>
          <label>component</label>
          <b-input v-model="model.component" id="component-info" />
        </b-col>
      </b-row>
      <b-row> </b-row>
      <b-popover
        variant="info"
        target="icon-info"
        triggers="focus"
        placement="bottom"
      >
        <template #title>Icone</template>
        <span class="lead p-2"
          >Digite o nome do icone ex: fa-cog ele aparece no circulo do navbar, e
          ao lado do menu</span
        >
      </b-popover>
      <b-popover
        variant="warning"
        target="menu-info"
        triggers="focus"
        placement="bottom"
      >
        <template #title>Menu</template>
        <div class="lead p-2 text-dark">Nome que aparecerá no menu</div>
      </b-popover>
      <b-popover
        variant="warning"
        target="description-info"
        triggers="focus"
        placement="bottom"
      >
        <template #title>Description</template>
        <div class="lead p-2 text-dark">
          Forma como aparecerá na aplicação "PERMISSÕES", cada nivel é separado
          por ponto, deve ser montado da seguinte forma: Menu.Lista. No caso ele
          aparecerá na aplicação "PERMISSÕES" Menu com sub-item Lista.
        </div>
      </b-popover>
      <b-popover
        variant="warning"
        target="route-info"
        triggers="focus"
        placement="bottom"
      >
        <template #title>Route</template>
        <div class="lead p-2 text-dark">
          Ele é o href, que será inserido como caminho que será redirecionado ao
          clicar no menu.
        </div>
      </b-popover>
      <b-popover
        variant="warning"
        target="path-info"
        triggers="focus"
        placement="bottom"
      >
        <template #title>Path</template>
        <div class="lead p-2 text-dark">
          Caminho criado dinamicamente pelo vue-router, assim que o sistema
          carrega a permissão (se tiver). Ex: {path:'aqui vai o path',
          name:'...', component:'...'} (estrutura vue router)
        </div>
      </b-popover>
      <b-popover
        variant="warning"
        target="name-info"
        triggers="focus"
        placement="bottom"
      >
        <template #title>Name</template>
        <div class="lead p-2 text-dark">
          Nome criado dinamicamente pelo vue-router, assim que o sistema carrega
          a permissão (se tiver). Ex: {path:'...', name:'aqui vai o name',
          component:'...'} (estrutura vue router)
        </div>
      </b-popover>
      <b-popover
        variant="warning"
        target="component-info"
        triggers="focus"
        placement="bottom"
      >
        <template #title>Component</template>
        <div class="lead p-2 text-dark">
          Componente carregado dinamicamente pelo vue-router, assim que o
          sistema carrega a permissão (se tiver). É o caminho que o componente
          se encontra na estrutura da aplicação Ex: {path:'...', name:'...',
          component:'aqui vai o component'} (estrutura vue router)
        </div>
      </b-popover>
      <b-popover
        variant="warning"
        target="title-info"
        triggers="focus"
        placement="bottom"
      >
        <template #title>Title</template>
        <div class="lead p-2 text-dark">
          Nome que vai ao lado do icone no navbar
        </div>
      </b-popover>
      <hr />
      <div class="d-flex justify-content-end">
        <b-button @click="update_permission" v-if="editing">Alterar</b-button>
        <b-button @click="create_permission" v-else>Criar</b-button>
      </div>
    </Card>

    <SweetModal ref="menuName">
      <template #title>
        <span>Digite o nome do menu que receberá o submenu.</span>
      </template>
      <b-input v-model="model.menu" />
      <template #button>
        <b-button @click="createTempMenu">Concluir</b-button>
      </template>
    </SweetModal>
    {{ tempSelected }}
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  data() {
    return {
      editing: false,
      componentName: "",
      model: {
        id: self.crypto.randomUUID(),
        application_id: "",
        icon: "",
        menu: "",
        menu_group_id: "",
        sub_menu: "",
        description: "",
        key: "",
        route: "",
        path: "",
        name: "",
        title: "",
        component: "",
        level: "",
        order_menu: "",
        type: "1",
        active: "1",
      },
      tempMenu: [],
      tempSelected: [],
    };
  },
  components: { Multiselect },
  computed: {
    menus() {
      const permissions = this.$store.state.permissionsFacility.permissions;
      return _.filter(permissions, (permission) => {
        return permission.application_id == this.model.application_id;
      });
    },
  },
  methods: {
    tag_remove() {
      setTimeout(() => {
        this.tempSelected = [];
        this.initialMenu();
      }, 100);
    },
    async update_permission() {
      let key = "";
      _.forEach(this.tempSelected, (item, idx) => {
        let normItem = item
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
        if (idx > 0) {
          key = key + `.${normItem.split(" ").join("_")}`;
        } else {
          key = key + `${normItem.split(" ").join("_")}`;
        }
      });
      this.model.key =
        key +
        `.habilitar.${this.componentName.toLowerCase().split(" ").join("_")}`;
      this.model.menu = this.tempSelected[0];
      this.model.sub_menu = this.tempSelected[1];
      const desc = this.tempSelected.join(".") + `.${this.componentName}`;
      this.model.description = desc;

      await this.$store.dispatch(
        "permissionsFacility/update_permission",
        this.model
      );
      this.$router.push("/facility");
    },
    async create_permission() {
      let key = "";
      _.forEach(this.tempSelected, (item, idx) => {
        let normItem = item
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
        if (idx > 0) {
          key = key + `.${normItem.split(" ").join("_")}`;
        } else {
          key = key + `${normItem.split(" ").join("_")}`;
        }
      });
      this.model.key =
        key +
        `.habilitar.${this.componentName.toLowerCase().split(" ").join("_")}`;
      this.model.menu = this.tempSelected[0];
      this.model.sub_menu = this.tempSelected[1];
      const desc = this.tempSelected.join(".") + `.${this.componentName}`;
      this.model.description = desc;

      await this.$store.dispatch(
        "permissionsFacility/create_permission",
        this.model
      );
      this.$router.push("/facility");
    },
    change_temp_menu(v) {
      let temp = [];
      if (this.tempSelected.length == 1) {
        const filtered = _.filter(this.menus, (menu) => {
          return menu.menu == v && menu.menu != menu.sub_menu;
        });
        const grouped = Object.keys(_.groupBy(filtered, "sub_menu"));
        temp = grouped;
      } else if (this.tempSelected.length >= 2) {
        const filtered = _.filter(this.menus, (menu) => {
          return (
            menu.menu == this.tempSelected[0] &&
            menu.sub_menu == this.tempSelected[1]
          );
        });
        // const filtered2 = _.filter(filtered, (item)=>{
        //   return item.description.split('.').length > this.tempSelected.length
        // })
        const norm = [];
        _.forEach(filtered, (item) => {
          const temp = item.description.split(".");
          if (
            temp[this.tempSelected.length] &&
            temp[this.tempSelected.length] !== this.tempSelected[1]
          ) {
            if (!norm.includes(temp[this.tempSelected.length])) {
              norm.push(temp[this.tempSelected.length]);
            }
          }
        });
        temp = norm;
      }
      this.tempMenu = temp;
    },
    initialMenu() {
      const grouped = _.groupBy(this.menus, "menu");
      const items = Object.keys(grouped);
      this.tempMenu = items;
    },
    selectedMenu(event) {
      if (event == "new") {
        this.$refs.menuName.open();
      } else {
        this.model.menu == event;
      }
    },
    createTempMenu() {
      this.tempMenu.push({ text: this.model.menu, value: this.model.menu });
      this.$refs.menuName.close();
    },
  },
  async mounted() {
    const applicationId = this.$route.params.applicationId;
    this.model.application_id = applicationId;
    const permissionId = this.$route.params.permissionId;

    if (permissionId) {
      const result = await this.$store.dispatch(
        "permissionsFacility/get_permission_by_id",
        permissionId
      );

      this.model = result;
      this.editing = true;
      const items = this.model.description.split(".");
      _.forEach(items, (item, index) => {
        if (index + 1 < items.length) {
          this.tempSelected.push(item);
        } else {
          this.componentName = item;
        }
      });
      this.change_temp_menu();
    } else {
      this.initialMenu();
    }
  },
};
</script>

<style>
</style>