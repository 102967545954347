<template>
  <div
    v-if="show"
    id="foreground"
    class="d-flex flex-column justify-content-center align-items-center"
  >
    <img src="../assets/gif/loader.gif" width="250" />
    <!-- <strong>{{ message }}</strong> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  created() {
    this.$root.$on("loading", (show) => {
      if (!show) {
        this.message = "";
      }
      this.show = show;
    });

    this.$root.$on("loading_message", (data) => {
      this.message = data;
    });
  },
};
</script>

<style lang="scss" scoped>
#foreground {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(#eee, 0.6);
  z-index: 99999999999;
}
</style>
