export default
    [
        //List
        {
            path: '/permissoes',
            name: 'permissions',
            meta: {
                icon: 'file-code',
                title: 'Permissões'
            },
            component: () => import('@/views/permissions/list/Index')
        },        
        {
            path: '/permissoes/adicionar',
            name: 'permissions.add',
            meta: {
                icon: 'file-code',
                title: 'Criar nova permissão'
            },
            component: () => import('@/views/permissions/list/AddEditView')
        },
        {
            path: '/permissoes/:groupId/editar',
            name: 'permissions.permissionId.edit',
            meta: {
                icon: 'file-code',
                title: 'Editar permissão'
            },
            component: () => import('@/views/permissions/list/AddEditView')
        },
        
        
    
    ]