import axios from 'axios'
import Cookie from 'js-cookie';
import router from '../router'


export default () => {

    const api =  axios.create({

        baseURL: process.env.VUE_APP_API_FACILITY_URL,
        withCredentials: false,
        'Access-Control-Allow-Credentials':true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookie.get('access_token')}`  
        }
    })

    api.interceptors.request.use(
        (config) => {
            config.headers.Authorization = `Bearer ${Cookie.get('access_token')}`;
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    api.interceptors.response.use(
        response => {
            return response
        },
        (error) => {
            console.log(error)
            if(error.response.data.code == 'token.expired') {
                router.push('/login');
            }
            return Promise.reject(error);
        }
    );

    return api;

}