<template>
  <div id="header" class="d-flex justify-content-between pr-4">
    <div
      id="header-content"
      class="d-flex align-items-center justify-content-between ml-3"
    >
      <div class="d-flex">
        <b-button
          @click="showMenu"
          class="
            menu-bars
            icon
            rounded-circle
            mr-3
            d-flex
            align-items-center
            justify-content-center
          "
        >
          <fa-icon icon="bars" />
        </b-button>
        <span
          class="
            icon
            rounded-circle
            mr-3
            d-flex
            align-items-center
            justify-content-center
          "
        >
          <fa-icon :icon="$route.meta.icon" />
        </span>
      </div>

      <div class="flex-grow-1">
        <h3 class="text-capitalize mb-0">{{ title }}</h3>
        <nav aria-label="breadcrumb mt-auto">
          <ol class="breadcrumb mb-0 align-items-center d-flex">
            <li class="breadcrumb-item" aria-current="page">
              <fa-icon icon="home" />
            </li>
            <li
              class="breadcrumb-item"
              :class="i + 1 == breadcrumbs.length ? 'active' : false"
              v-for="(breadcrumb, i) in breadcrumbs"
              :key="i"
            >
              <router-link
                v-if="breadcrumb.path"
                :to="{ name: breadcrumb.path }"
                >{{ breadcrumb.title }}</router-link
              >
              <template v-else>{{ breadcrumb.title }}</template>
            </li>
            <li class="breadcrumb-item active">
              {{ title }}
            </li>
          </ol>
        </nav>
      </div>
      <!-- <user-profile class="flex-grow-0" /> -->
    </div>
    <div class="d-flex align-items-center pr-4">
      <div>
        <fa-icon
          class="notification-icon"
          icon="bell"
          id="notification"
        ></fa-icon>
        <b-popover
          custom-class="notification-popover"
          target="notification"
          placement="bottom"
          variant="default"
          triggers="focus"
        >
          <template style="overflow: hidden" #title>Notificações</template>
          <div class="p-3">
            <span>Nenhuma notificação no momento</span>
          </div>
        </b-popover>
      </div>
      <div style="z-index:1">
        <img
          :src="avatar"
          class="rounded-circle shadow-sm bg-white p-1 mr-2"
          height="50"
          width="50"
        />
        <b-dropdown variant="link" id="dropdown-1" no-caret>
          <template #button-content>
            <div class="d-flex align-items">
              <div class="d-flex flex-column">
                <strong>{{ userName }}</strong>
                <small style="opacity: 0.6">({{ unityName }})</small>
              </div>
              <fa-icon icon="sort-down" class="ml-2"></fa-icon>
            </div>
          </template>

          <b-dropdown-item>Editar perfil</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="logout">Sair</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {};
  },
  computed: {
    userName() {
      return this.$store.state.auth.currentUser.name;
    },
    unityName() {
      return this.$store.state.auth.currentUser.company_name;
    },
    title() {
      return this.$route.meta.title;
    },
    breadcrumbs() {
      let paths = this.$route.meta.breadcrumb;
      return paths;
    },
    avatar() {
      return `https://ui-avatars.com/api/?name=${this.userName}&background=0D8ABC&color=fff&length=1`;
    },
  },
  methods: {
    showMenu() {
      this.$emit("showMenu");
    },
    async logout() {
      await this.$store.dispatch("auth/logout");
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped lang="scss">
.notification-popover {
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.91);
  box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.91);
}
.notification-icon {
  margin-right: 10px;
  width: 15px;
  height: 15px;
  opacity: 0.4;
  outline: none;
}
#header {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: $header-height * 2;
    border-bottom-left-radius: $header-height;
    // background: theme-color("light-blue");
    background-color: #1e7f9d;
    top: 0;
    right: -7px !important;
    transform: rotate(-3deg) translateY(-3rem);
    z-index: -2;
  }
}
#header-content {
  position: relative;
  height: $header-height;
}
.icon {
  width: 4rem;
  height: 4rem;
  background-color: transparentize(black, 0.9);
  color: white;
}
.menu-bars {
  display: none !important;
}
@media (max-width: 1024px) {
  .menu-bars {
    display: flex !important;
  }
}
</style>
